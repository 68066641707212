<template>
  <div>
    <div class="content">
      <div class="all">
      <div class="SearchBar">
        <el-form :inline="true" class="searchBoxForm">
          <el-form-item label="工程编号/名称" class="searchItem">
            <el-input
              size="small"
              v-model="pageParams.projectStr"
              placeholder="请输入"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="设计时间" class="searchItem">
            <el-date-picker
              size="small"
              v-model="date"
              @change="setSearchDate"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
              format="yyyy-MM-dd HH:mm"
              value-format="yyyy-MM-dd HH:mm"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="" class="searchItem">
             <el-button type="primary" @click="handleCurrentChange(1)" size="small" >查询</el-button>
          </el-form-item>
          
        </el-form>
      </div>
    </div>
    <div class="contain">
      <!-- <div class="table-height"> -->
      <div class="tableTool">
         <span style="float:right"> 
        <el-button type="primary" plain size="small" @click="onAddDesign"
          >上传设计</el-button
        >
         </span>
      </div>
      <el-table
            ref="tableData"
            :data="tableData"
            style="width: 100%;margin: 15px 0;"
            border
            height="calc(100vh - 384px)"
            :stripe="true"
            
          >
        <el-table-column
          type="index"
          :index="indexMethod"
          label="序号"
          align="center"
          width="50"
        >
        </el-table-column>
        <el-table-column
          v-for="(item, index) in tableHeader"
          :key="index"
          :label="item.label"
          :prop="item.property"
          align="center"
        >
          <template slot-scope="scope">
            <div>{{ scope.row[scope.column.property] || "-" }}</div>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="left" width="150">
          <template slot-scope="scope">
           
              <el-button type="primary" size="small" @click="onShowDesign(scope.row)"
                >查看</el-button
              >
           
          </template>
        </el-table-column>
      </el-table>
    <!-- </div> -->
      <el-pagination
        background
        layout="total, prev, pager, next,sizes, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="pageParams.current"
        :page-sizes="[ 30, 50, 100]"
        :page-size="pageParams.size"
      >
      </el-pagination>
    </div></div>

    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="30%"
      @closed="handleClose"
      :close-on-click-modal="false"
      @open="openD"
    >
      <el-form
        :model="form"
        :rules="rules"
        label-width="100px"
        ref="form"
        v-if="!isInfo"
        label-position="left"
      >
        <el-form-item label="工程名称" prop="projectId">
          <el-select v-model="form.projectId"  filterable placeholder="请选择">
            <el-option
              v-for="item in projectDic"
              :key="item.projectId"
              :label="item.projectName"
              :value="item.projectId"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="" >
          <span slot="label">
          <span style="color:red;margin-left:4px">*</span>  原始图纸
            </span>
          <el-upload
            action="#"
            :http-request="onFileList"
            :on-remove="onDeleteFileList"
            :on-change="beforHFhandleChangeImg"
            :auto-upload="true"
            :file-list="fileList"
             
          >
            <el-button size="small" type="primary">点击上传</el-button>
          </el-upload>
        </el-form-item>
        <el-form-item label="">
          <span slot="label">
          <span style="color:red;margin-left:4px">*</span>  设计pdf
            </span>
          <el-upload
            action="#"
            :auto-upload="true"
            :http-request="onPdfUpload"
            :on-remove="onDeletePdfList"
             :on-change="beforHFhandleChangePDF"
            accept=".pdf,.PDF"
            :file-list="pdfList"
          >
            <el-button size="small" type="primary">点击上传</el-button>
          </el-upload>
        </el-form-item>
        <el-form-item label="设计说明">
          <el-input
            type="textarea"
            :rows="2"
            maxlength="100"
            placeholder="请输入内容"
            v-model="form.designDescrption"
          ></el-input>
        </el-form-item>
      </el-form>
      <div class="contrantInfo" v-else>
        <div class="info-content">
          <el-row class="info-item">
            <span class="label">合同来源：</span>
            <span class="content-item">{{ form.projectName }}</span>
          </el-row>
          <el-row
            class="info-item"
            v-for="(item, i) in form.designFileUrl"
            :key="i + 'file'"
          >
            <span class="label">原始图纸：</span>
            <span class="content-item">
              <el-tooltip
                class="item"
                effect="dark"
                :content="item"
                placement="top"
              >
                <span class="content-text">{{ item }}</span>
              </el-tooltip>
              <el-button type="text" icon="el-icon-download" @click="onDownFile(item)"></el-button>
            </span>
          </el-row>
          <el-row
            class="info-item"
            v-for="(item, i) in form.designPdfUrl"
            :key="i + 'pdf'"
          >
            <span class="label">设计pdf：</span>
            <!-- 改成按钮 -->
            <span class="content-item">
              <el-tooltip
                class="item"
                effect="dark"
                :content="item"
                placement="top"
              >
                <span class="content-text">{{ item }}</span>
              </el-tooltip>
              <el-button type="text" icon="el-icon-download" @click="onDownFile(item)"></el-button>
            </span>
          </el-row>
          <el-row class="info-item">
            <span class="label">设计说明：</span>
            <!-- 改成按钮 -->
            <span class="content-item"> {{form.designDescription}} </span>
          </el-row>
        </div>
      </div>
      <span slot="footer" class="dialog-footer" v-if="!isInfo">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="onSumbitForm('form')"
          >确 定</el-button
        >
      </span>
      <span slot="footer" class="dialog-footer" v-else>
        <el-button @click="dialogVisible = false">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>


<script>
import pageMixins from "@/utils/pageMixins";
import axios from "@/apis/designApi";
import getNowFormatDate from "./../../common/js/nowDate.js";
export default {
  mixins: [pageMixins],
  data() {
    return {
      pageParams: {
        startTime: "",
        projectStr: "",
        endTime: "",
        current: 1,
        size: 100,
        total:0
      }, //  分页查询参数
      tableHeader: [
        { label: "工程编号", property: "projectNumber" },
        { label: "工程名称", property: "projectName" },
        { label: "工程类型", property: "projectType" },
        { label: "安装施工队", property: "teamName" },
        { label: "设计人", property: "designRealname" },
        { label: "设计时间", property: "designTime" },
      ], //  表头数据
      tableData: [], //  表格数据
      dialogVisible: false, //  上传设计弹窗开关
      date: [], //  时间选择
      form: {
        projectId: "",
        designDescrption: "",
        designFileUrl: [],
        designPdfUrl: [],
      }, //  表单
      isInfo: false, //  是否为详情
      rules: {
        projectId: [{ required: true, message: "请选择", trigger: "change" }],
        // designFileUrl: [{ required: true, message: "请上传", trigger: "blur" }],
        // designPdfUrl: [{ required: true, message: "请上传", trigger: ["blur",'change'] }],
      },
      projectDic: [{projectId:1,projectName:11111}], //  工程名称字典
      fileList: [], //  文件列表
      pdfList: [], //  pdf列表
      isDisabled: false, //  禁用表单
      title:'',
      repairBeforFilefileList:[],
      repairBeforFilefileListPDF:[]
    };
  },
  mounted() {
    this.loadList();
    this.getEngineeringList();
  },
  methods: {
    /**
     * 点击列表查看按钮
     */
    onShowDesign(row) {
      this.title='查看'
      this.isInfo = true;
      let { designFileUrl, designPdfUrl, projectName, designDescription } = row;
      designFileUrl = designFileUrl.split(",");
      designPdfUrl = designPdfUrl.split(",");
      Object.assign(this.form, {
        designFileUrl,
        designPdfUrl,
        projectName,
        designDescription,
      });
      this.dialogVisible = true;
    },
    /**加载列表数据 */
    async loadList() {
      let { pageParams } = this;
      try {
        let res = await axios.getFormalDesignTableData(pageParams);
        this.tableData = res.data.records;
        this.total = res.data.total;
      } catch (e) {
        this.tableData = [];
      }
    },
    /**关闭弹窗进行的操作 */
    handleClose() {
      this.pdfList = [];
      this.fileList = [];
      (this.form = {
        projectId: "",
        designDescrption: "",
        designFileUrl: [],
        designPdfUrl: [],
      }), //  表单
        this.$refs.form.resetFields();
    },
    /**格式化时间 */
    setSearchDate() {
      let { date } = this;
      //  判断是赋值还是清空清空会将数组变成null值
      if (date) {
        this.pageParams.startTime = date[0];
        this.pageParams.endTime = date[1];
      } else {
        this.pageParams.startTime = "";
        this.pageParams.endTime = "";
      }
    },
    /**
     * 上传设计
     */
    onAddDesign() {
      this.title = '上传设计'
      this.isInfo = false;
      this.$nextTick(() => {
        this.dialogVisible = true;
      });
    },
    openD(){
      this.getEngineeringList()
    },
    /**
     * 获取工程列表
     */
    async getEngineeringList() {
      try {
        let res = await axios.getEngineeringList();
        this.projectDic = res.data;
      } catch (e) {}
    },
    //  上传文件
    async onFileList(files) {
      try {
        // if(!this.form.projectId){
        //   this.$message.warning('请选择工程名称')
        //   this.fileList = [];
        //   return;
        // }
        // let projectName = this.projectDic.find(item=>item.projectId==this.form.projectId).projectName;
        
        let uploadObj = new FormData();
        uploadObj.append("file", files.file);
        // uploadObj.append("text", '工程名称：'+projectName+'##当前时间：'+getNowFormatDate()+'##用户名：'+localStorage.getItem("userName"));
        let res = await axios.uploadImg(uploadObj);
        this.form.designFileUrl.push(res.data.url);
      } catch (e) {}
    },
     beforHFhandleChangeImg(file, fileList) {
      this.repairBeforFilefileList = fileList
    },
    getArrayIndex(arr, obj) {
      var i = arr.length;
      while (i--) {
        if (arr[i] === obj) {
          return i;
        }
      }
      return -1;
    },
    // 删除文件
    onDeleteFileList(file) {
      let index = this.getArrayIndex(this.repairBeforFilefileList,file);
       this.form.designFileUrl.splice(index,1)
      
    },
    async onPdfUpload(files) {
      try {
        let uploadObj = new FormData();
        uploadObj.append("file", files.file);
        let res = await axios.uploadImg(uploadObj);
        this.form.designPdfUrl.push(res.data.url);
        
      } catch (e) {
        console.log(e, "e");
      }
    },
    beforHFhandleChangePDF(file, fileList) {
      this.repairBeforFilefileListPDF = fileList
    },
    /**
     * 删除pdf
     */
    onDeletePdfList(file) {
      let index = this.getArrayIndex(this.repairBeforFilefileListPDF,file);
       this.form.designPdfUrl.splice(index,1)
    },
    /**
     * 新增设计
     */
    async onSumbitForm() {
      let isValid = await this.$refs.form.validate();
      try {
        
        if(this.form.designFileUrl&&this.form.designFileUrl.length<=0){
          this.$message.error("请上传原图纸");
            return;
        }
         if(this.form.designPdfUrl&&this.form.designPdfUrl.length<=0){
          this.$message.error("请上传设计pdf");
            return;
        }
        let { form } = this;
        let obj = JSON.parse(JSON.stringify(form));
        obj.designFileUrl = obj.designFileUrl.join(",");
        obj.designPdfUrl = obj.designPdfUrl.join(",");
        await axios.onAddDesign(obj);
        this.$message.success("新增设计成功");
        this.dialogVisible = false;
        this.loadList();
      } catch (e) {}
    },
    /**
     * 下载按钮
     */
    onDownFile(url) {
      window.open(url);
    },
  },
};
</script>

<style lang="scss" scoped>
.searchBox {
  .searchBoxForm {
    display: flex;
    align-items: center;
    
  }
 
}
.tableTool{    height: 25px;}
 .searchItem {
      margin-bottom: 1%;
       margin-left: 1%;
    }
// .info-item {
//   margin-bottom: 20px;
// }
.label {
  color: #909399;
}
.content-item {
  color: #444444;
}
.SearchBar {
  /* border: 1px solid #ecedf1; */
  border-radius: 5px;
  margin: 10px;
  padding-top: 1%;

  background-color: #ffffff;
}
.all {
  background-color:#f3f4f8;height:auto
}
.content {
  background: #f3f4f8;
  width: 100%;
  border-radius: 6px;
  height: calc(100vh - 150px);

}
.content-text {
  display: inline-block;
  width: 200px;
  vertical-align: middle;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>